<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0 overflow-y-auto"
		:height="userFormHeight"
	>
		<v-row dense no-gutters class="mt-16 mr-md-4 ml-md-1 mr-lg-4 ml-lg-1 mr-xl-4 ml-xl-1">
			<v-col cols="12" class="mt-2">
				<v-card outlined class="mb-5">
					<v-row no-gutters class="mb-2">
						<v-col cols="12" class="text-center">
							<v-card
								tile
								elevation="5"
								:max-width="$vuetify.breakpoint.mdAndUp ? 150 : 100"
								:min-height="$vuetify.breakpoint.mdAndUp ? 150 : 100"
								class="mx-auto mt-n16 rounded-circle">
								
								<v-img
									:src="imageProfile"
									width="$vuetify.breakpoint.mdAndUp ? 150 : 100"
									height="$vuetify.breakpoint.mdAndUp ? 150 : 100"
								></v-img>
							</v-card>
							
							<v-tooltip right>
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn
										v-on="onToolTip"
										color="primary"
										fab
										x-small
										dark
										bottom
										class="mt-n16 ml-16 mr-n4 mr-sm-n4 mr-md-n8 mr-lg-n8 mr-xl-n8"
										@click="uploadProfileOn"
									>
										<v-icon>mdi-camera</v-icon>
									</v-btn>
								</template>
								<small>Upload</small>
							</v-tooltip>
							
						</v-col>
						
						<v-col cols="12" class="text-center mt-n5">
							<v-list-item>
								<v-list-item-content class="pt-0 pb-0">
								<v-list-item-title class="title">{{ fname }}</v-list-item-title>
								<v-list-item-subtitle>{{ rank }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1">
							PERSONAL INFORMATION
							<v-tooltip bottom v-if="statusPersonalInfo == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('basicinfo');" icon color="info" class="mt-n1" id="v-basicinfo-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusPersonalInfo == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getProfile" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusPersonalInfo == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-row dense no-gutters class="px-4 mt-3">
								<v-col
									v-for="item in personalInfoField"
									:key="item.key"
									cols="12" sm="6" md="3" lg="3" xl="3"
									class="text-center pt-0"
								>
									<v-text-field
										dense
										:value="personalInfo[item.key]"
										:label="item.label"
										disabled
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							CONTACT INFORMATION
							<v-tooltip bottom v-if="statusContactInfo == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('contactinfo');" icon color="info" class="mt-n1" id="v-contactinfo-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusContactInfo == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getContact" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusContactInfo == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-row dense no-gutters class="px-4 mt-3">
								<v-col cols="12" sm="12" md="12" lg="6" xl="6" class="text-center pt-0">
									<v-text-field
										dense
										:value="contactInfo.MAIN_ADDR1"
										label="Primary Address"
										disabled
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="12" md="12" lg="6" xl="6" class="text-center pt-0">
									<v-text-field
										dense
										:value="contactInfo.SECONDARY_ADDR1"
										label="Secondary Address"
										disabled
									></v-text-field>
								</v-col>
								
								<v-col
									v-for="item in contactInfoField"
									:key="item.key"
									cols="12" sm="6" md="3" lg="3" xl="3"
									class="text-center pt-0"
								>
									<v-text-field
										dense
										:value="contactInfo[item.key]"
										:label="item.label"
										disabled
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							EDUCATION
							<v-tooltip bottom v-if="statusEducation == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('education');" icon color="info" class="mt-n1" id="v-education-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusEducation == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getProfile" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusEducation == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="education.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>LEVEL</th>
											<th>SCHOOL NAME</th>
											<th>COURSE</th>
											<th class="text-center">START YEAR</th>
											<th class="text-center">END YEAR</th>
										</tr>
									</thead>
									
									<tbody>
										<tr
											v-for="item in education"
											:key="item.EDUC_LEVEL"
										>
											<td>{{ item.EDUC_LEVEL }}</td>
											<td>{{ item.SCHOOL }}</td>
											<td>{{ item.EDUC_COURSE }}</td>
											<td class="text-center">{{ item.EDUC_START }}</td>
											<td class="text-center">{{ item.EDUC_FINISH }}</td>
										</tr>
										
										<tr v-if="education.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>	
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="education.length > 5? '250px' : 'undefined'"
								v-else
							>	
								<template v-slot:default>
									<thead>
										<tr>
											<th>LEVEL<br/>SCHOOL NAME</th>
											<th class="text-center">START YEAR<br/>END YEAR</th>
										</tr>
									</thead>
									
									<tbody>
										<tr
											v-for="item in education"
											:key="item.EDUC_LEVEL"
										>
											<td>{{ item.EDUC_LEVEL }}<br/>{{ item.SCHOOL }}</td>
											<td class="text-center">{{ item.EDUC_START }}<br/>{{ item.EDUC_FINISH }}</td>
										</tr>
										
										<tr v-if="education.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							FAMILY
							<v-tooltip bottom v-if="statusFamily == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('family');" icon color="info" class="mt-n1" id="v-family-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusFamily == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getFamily" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusFamily == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="family.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>NAME</th>
											<th>RELATIONSHIP</th>
											<th class="text-center">DATE OF BIRTH</th>
											<th>PLACE OF BIRTH</th>
											<th class="text-center">BENEFICIARY</th>
											<th class="text-center">DEPENDENT</th>
											<th class="text-center">CONTACT PERSON</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in family"
											:key="item.NAME"
										>
											<td>{{ item.NAME }}</td>
											<td>{{ item.RELATIONSHIP }}</td>
											<td class="text-center">{{ item.BDAY }}</td>
											<td>{{ item.POB }}</td>
											<td class="text-center">
												<v-icon v-if="item.BENEFACTOR == 1">mdi-check</v-icon>
												<v-icon v-else>mdi-close</v-icon>
											</td>
											<td class="text-center">
												<v-icon v-if="item.DEPENDENT == 1">mdi-check</v-icon>
												<v-icon v-else>mdi-close</v-icon>
											</td>
											<td class="text-center">
												<v-icon v-if="item.CONTACT_PERSON == 1">mdi-check</v-icon>
												<v-icon v-else>mdi-close</v-icon>
											</td>
										</tr>
										
										<tr v-if="family.length == 0">
											<td colspan="7">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="family.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>NAME<br/>RELATIONSHIP</th>
											<th>DATE OF BIRTH<br/>PLACE OF BIRTH</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in family"
											:key="item.NAME"
										>
											<td>{{ item.NAME }}<br/>{{ item.RELATIONSHIP }}</td>
											<td>{{ item.BDAY }}<br/>{{ item.POB }}</td>
										</tr>
										
										<tr v-if="family.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							EMPLOYMENT
							<v-tooltip bottom v-if="statusEmployment == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('employment');" icon color="info" class="mt-n1" id="v-employment-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusEmployment == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getEmployment" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusEmployment == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="employment.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>AGENCY</th>
											<th>VESSEL NAME</th>
											<th class="text-center">RANK</th>
											<th>VESSEL TYPE</th>
											<th class="text-center">SIGN ON</th>
											<th class="text-center">SIGN OFF</th>
											<th class="text-center">TERMINATION CAUSE</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in employment"
											:key="item.NAME"
										>
											<td>{{ item.AGENCY }}</td>
											<td>{{ item.H_VSSLNAME }}</td>
											<td class="text-center">{{ item.H_RANKCODE }}</td>
											<td>{{ item.H_VSSLTYPE }}</td>
											<td class="text-center">{{ item.H_SIGNONDATE }}</td>
											<td class="text-center">{{ item.H_SIGNOFDATE }}</td>
											<td class="text-center">{{ item.TERMDESC }}</td>
										</tr>
										
										<tr v-if="employment.length == 0">
											<td colspan="7">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="employment.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>AGENCY<br/>VESSEL NAME</th>
											<th class="text-center">RANK<br/>VESSEL TYPE</th>
											<th class="text-center">SIGN ON<br/>SIGN OFF</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in employment"
											:key="item.NAME"
										>
											<td>{{ item.AGENCY }}<br/>{{ item.H_VSSLNAME }}</td>
											<td class="text-center">{{ item.H_RANKCODE }}<br/>{{ item.H_VSSLTYPE }}</td>
											<td class="text-center">{{ item.H_SIGNONDATE }}<br/>{{ item.H_SIGNOFDATE }}</td>
										</tr>
										
										<tr v-if="employment.length == 0">
											<td colspan="3">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							LOCAL DOCUMENT
							<v-tooltip bottom v-if="statusLocalDoc == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('localdocument');" icon color="info" class="mt-n1" id="v-localdocument-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusLocalDoc == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getLocaldocs" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusLocalDoc == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="localDocs.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th>PLACE OF ISSUED</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in localDocs"
											:key="item.DOC_NAME"
										>
											<td>{{ item.DOC_NAME }}</td>
											<td>{{ item.DOC_NUMBER }}</td>
											<td>{{ item.PLACE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="localDocs.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="localDocs.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in localDocs"
											:key="item.DOC_NAME"
										>
											<td>{{ item.DOC_NAME }}<br/>{{ item.DOC_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="localDocs.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							FOREIGN DOCUMENT
							<v-tooltip bottom v-if="statusForeignDoc == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('foreigndocument');" icon color="info" class="mt-n1" id="v-foreigndocument-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusForeignDoc == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getForeigndocs" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusForeignDoc == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="foreignDocs.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th>PLACE OF ISSUED</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in foreignDocs"
											:key="item.FOREIGN_DOC_NAME"
										>
											<td>{{ item.FOREIGN_DOC_NAME }}</td>
											<td>{{ item.FDOC_NUMBER }}</td>
											<td>{{ item.PLACE }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="foreignDocs.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="foreignDocs.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in foreignDocs"
											:key="item.FOREIGN_DOC_NAME"
										>
											<td>{{ item.FOREIGN_DOC_NAME }}<br/>{{ item.FDOC_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="foreignDocs.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							TRAININGS
							<v-tooltip bottom v-if="statusTraining == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('trainings');" icon color="info" class="mt-n1" id="v-trainings-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusTraining == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getTraining" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusTraining == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="training.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>TRAINING NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th>TRAINING CENTER</th>
											<th class="text-center">DATE TAKEN</th>
											<th class="text-center">DATE FINISH</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in training"
											:key="item.TRNG_NAME"
										>
											<td>{{ item.TRNG_NAME }}</td>
											<td>{{ item.TRNG_CERTNUMB }}</td>
											<td>{{ item.TRNG_CENTER }}</td>
											<td class="text-center">{{ item.TRNG_DATETAKEN }}</td>
											<td class="text-center">{{ item.TRNG_DATE_FIN }}</td>
											<td class="text-center">{{ item.TRNG_DATEISSUED }}</td>
											<td class="text-center">{{ item.TRNG_DATE_VALID }}</td>
										</tr>
										
										<tr v-if="training.length == 0">
											<td colspan="7">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="training.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>TRAINING NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE TAKEN<br/>DATE FINISH</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in training"
											:key="item.TRNG_NAME"
										>
											<td>{{ item.TRNG_NAME }}<br/>{{ item.TRNG_CERTNUMB }}</td>
											<td class="text-center">{{ item.TRNG_DATETAKEN }}<br/>{{ item.TRNG_DATE_FIN }}</td>
											<td class="text-center">{{ item.TRNG_DATEISSUED }}<br/>{{ item.TRNG_DATE_VALID }}</td>
										</tr>
										
										<tr v-if="training.length == 0">
											<td colspan="3">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							CERTIFICATES
							<v-tooltip bottom v-if="statusCertificate == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('certificate');" icon color="info" class="mt-n1" id="v-certificate-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusCertificate == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getCertificate" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusCertificate == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="certificates.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>CERTIFICATE NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in certificates"
											:key="item.CERT_NAME"
										>
											<td>{{ item.CERT_NAME }}</td>
											<td>{{ item.CERT_NUMB }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="certificates.length == 0">
											<td colspan="4">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="certificates.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>CERTIFICATE NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in certificates"
											:key="item.CERT_NAME"
										>
											<td>{{ item.CERT_NAME }}<br/>{{ item.CERT_NUMB }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="certificates.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							VISA
							<v-tooltip bottom v-if="statusVisa == 'loaded'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="$router.push('visa');" icon color="info" class="mt-n1" id="v-visa-step">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
							
							<v-tooltip bottom v-if="statusVisa == 'reload'">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn small v-on="onToolTip" @click="getVisa" icon color="info" class="mt-n1">
										<v-icon>mdi-refresh-circle</v-icon>
									</v-btn>
								</template>
								<small>Reload</small>
							</v-tooltip>
							
							<v-progress-circular
								v-if="statusVisa == 'loading'"
								indeterminate
								color="primary"
								size="19"
								width="3"
								class="ml-1"
							></v-progress-circular>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="visa.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>VISA NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th class="text-center">DATE APPLIED</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in visa"
											:key="item.VISANAME"
										>
											<td>{{ item.VISANAME }}</td>
											<td>{{ item.VISA_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_APPLIED }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="visa.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="visa.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>VISA NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in visa"
											:key="item.VISANAME"
										>
											<td>{{ item.VISANAME }}<br/>{{ item.VISA_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="visa.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		
		<v-tour name="vTourSummary" :steps="vTourSteps" :options="vTourOptions" :callbacks="vTourCallbacks"></v-tour>
	</v-card>
</template>

<script>

import { mapGetters } from 'vuex';
import { UPLOAD_PROFILE_OPEN } from '@/store/actions/user';

export default {
	data () {
		return {
			statusPersonalInfo	: 'loading',
			statusContactInfo	: 'loading',
			statusEducation		: 'loading',
			statusFamily		: 'loading',
			statusEmployment	: 'loading',
			statusLocalDoc		: 'loading',
			statusForeignDoc	: 'loading',
			statusTraining		: 'loading',
			statusCertificate	: 'loading',
			statusVisa			: 'loading',
			
			personalInfo		: [],
			personalInfoField	: [
				{ label: 'Date of Birth',	key: 'DOB'},
				{ label: 'Place of Birth',	key: 'POB'},
				{ label: 'Gender',			key: 'GENDER'},
				{ label: 'Civil Status',	key: 'CIVIL_STATUS'},
				{ label: 'Nationality',		key: 'NATIONALITY'},
				{ label: 'Religion',		key: 'RELIGION'},
				{ label: 'Height',			key: 'HEIGHT'},
				{ label: 'Weight',			key: 'WEIGHT'},
				{ label: 'Color of Eyes',	key: 'EYECOLOR'},
				{ label: 'Color of Hair',	key: 'HAIRCOLOR'},
				{ label: 'Shoe Size',		key: 'SHOE_SIZE'},
				{ label: 'Coverall Size',	key: 'COVERALL_SIZE'},
				{ label: 'SSS ID Number',	key: 'SSS'},
				{ label: 'PAG-IBIG (HDMF) ID Number',	key: 'PAG_IBIG'},
				{ label: 'TIN ID Number',	key: 'TIN'},
				{ label: 'PhilHealth ID Number',		key: 'PHILHEALTH'}
			],
			contactInfo			: [],
			contactInfoField	: [
				{ label: 'Mobile Number',	key: 'MOBILE'},
				{ label: 'Secondary Mobile Number',		key: 'SECONDARY_MOBILE'},
				{ label: 'Telephone',		key: 'TELEPHONE'},
				{ label: 'Email Address',	key: 'EMAIL'}
			],
			education			: [],
			family				: [],
			employment			: [],
			localDocs			: [],
			foreignDocs			: [],
			training			: [],
			certificates		: [],
			visa				: [],
			
			vTourTargetArr: [],
			vTourTarget: '',
			vTourSteps: [
				{
					target: '#v--step-1',
					content: 'Click to update details'
				},
			],
			vTourOptions: {
				startTimeout: 500,
				labels: {
					buttonStop: 'Skip'
				}
			},
			vTourCallbacks:{
				onFinish: this.openApplication
			},
		}
	},
	methods: {
		
		openApplication(){
			this.$router.push(this.vTourTarget);
		},
		startGuide(){
			if( this.vTourTargetArr.length !== 0 ){
				this.vTourTarget = this.vTourTargetArr.shift();
				this.$tours['vTourSummary'].start();
			}
		},
		
		getProfile () {
			this.$http.get('profile')
			.then((resp) => {
				
				if(resp.data.SSS && resp.data.SSS != null){
					const sss = resp.data.SSS.replace(/[^\d]/g, "");
					resp.data.SSS = (sss != '')? sss.substr(0, 2)+'-'+sss.substr(2, 7)+'-'+sss.substr(9, 1) : '';
				}else{
					resp.data.SSS = '';
				}
				
				if(resp.data.PAG_IBIG && resp.data.PAG_IBIG != null){
					const hdmf = resp.data.PAG_IBIG.replace(/[^\d]/g, "");
					resp.data.PAG_IBIG = (hdmf != '')? hdmf.substr(0, 4)+'-'+hdmf.substr(4, 4)+'-'+hdmf.substr(8, 4) : '';
				}else{
					resp.data.PAG_IBIG = '';
				}

				if(resp.data.TIN && resp.data.TIN != null){
					const tin = resp.data.TIN.replace(/[^\d]/g, "");
					resp.data.TIN = (tin != '')? tin.substr(0, 3)+'-'+tin.substr(3, 3)+'-'+tin.substr(6, 3) : '';
				}else{
					resp.data.TIN = '';
				}

				if(resp.data.PHILHEALTH && resp.data.PHILHEALTH != null){
					const philHealth = resp.data.PHILHEALTH.replace(/[^\d]/g, "");
					resp.data.PHILHEALTH = (philHealth != '')? philHealth.substr(0, 2)+'-'+philHealth.substr(2, 9)+'-'+philHealth.substr(11, 1) : '';
				}else{
					resp.data.PHILHEALTH = '';
				}
				
				if(!this.isValidDate(resp.data.DOB))resp.data.DOB = '';
				
				this.personalInfo = resp.data;
				this.statusPersonalInfo = 'loaded';
				
				if( this.personalInfo.GENDER === '' ){
					this.vTourTargetArr.push('basicinfo');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusPersonalInfo = 'reload';
			});
		},
		getContact () {
			this.$http.get('contact')
			.then((resp) => {
				
				if(resp.data.MOBILE && resp.data.MOBILE != null){
					const mobile = resp.data.MOBILE.replace(/[^\d]/g, "");
					resp.data.MOBILE = (mobile != '')? mobile.substr(0, 4)+'-'+mobile.substr(4, 4)+'-'+mobile.substr(8, 3) : '';
				}else{
					resp.data.MOBILE = '';
				}
				
				if(resp.data.SECONDARY_MOBILE && resp.data.SECONDARY_MOBILE != null){
					const mobile2 = resp.data.SECONDARY_MOBILE.replace(/[^\d]/g, "");
					resp.data.SECONDARY_MOBILE = (mobile2 != '')? mobile2.substr(0, 4)+'-'+mobile2.substr(4, 4)+'-'+mobile2.substr(8, 3) : '';
				}else{
					resp.data.SECONDARY_MOBILE = '';
				}
				
				if(resp.data.TELEPHONE && resp.data.TELEPHONE != null){
					const telephone = resp.data.TELEPHONE.replace(/[^\d]/g, "");
					resp.data.TELEPHONE = (telephone != '')? telephone.substr(0, 2)+'-'+telephone.substr(2, 4)+'-'+telephone.substr(6, 4) : '';
				}else{
					resp.data.TELEPHONE = '';
				}
				
				if(resp.data.SECONDARY_ADDR1 && resp.data.SECONDARY_ADDR1 != null){
					resp.data.SECONDARY_ADDR1 = resp.data.SECONDARY_ADDR1+' '+resp.data.SECONDARY_ADDR2+' '+resp.data.SECONDARY_ADDR3
				}else{
					resp.data.SECONDARY_ADDR1 = '';
				}
				
				if(resp.data.MAIN_ADDR1 && resp.data.MAIN_ADDR1 != null){
					resp.data.MAIN_ADDR1 = resp.data.MAIN_ADDR1+' '+resp.data.MAIN_ADDR2+' '+resp.data.MAIN_ADDR3
				}else{
					resp.data.MAIN_ADDR1 = '';
				}
				
				this.contactInfo = resp.data;
				this.statusContactInfo = 'loaded';
				
				if( this.contactInfo.MOBILE === '' ){
					this.vTourTargetArr.push('contactinfo');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusContactInfo = 'reload';
			});
		},
		getEducation () {
			this.$http.get('summary/education')
			.then((resp) => {
				this.education = resp.data;
				this.statusEducation = 'loaded';
				if( this.education.length === 0 ){
					this.vTourTargetArr.push('education');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusEducation = 'reload';
			});
		},
		getFamily () {
			this.$http.get('summary/family')
			.then((resp) => {
				this.family = resp.data;
				this.statusFamily = 'loaded';
				if( this.family.length === 0 ){
					this.vTourTargetArr.push('family');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusFamily = 'reload';
			});
		},
		getEmployment () {
			this.$http.get('summary/employment')
			.then((resp) => {
				this.employment = resp.data;
				this.statusEmployment = 'loaded';
				if( this.employment.length === 0 ){
					this.vTourTargetArr.push('employment');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusEmployment = 'reload';
			});
		},
		getLocaldocs () {
			this.$http.get('summary/localdocs')
			.then((resp) => {
				this.localDocs = resp.data;
				this.statusLocalDoc = 'loaded';
				if( this.localDocs.length === 0 ){
					this.vTourTargetArr.push('localdocument');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusLocalDoc = 'reload';
			});
		},
		getForeigndocs () {
			this.$http.get('summary/foreigndocs')
			.then((resp) => {
				this.foreignDocs = resp.data;
				this.statusForeignDoc = 'loaded';
				if( this.foreignDocs.length === 0 ){
					this.vTourTargetArr.push('foreigndocument');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusForeignDoc = 'reload';
			});
		},
		getTraining () {
			this.$http.get('summary/training')
			.then((resp) => {
				this.training = resp.data;
				this.statusTraining = 'loaded';
				if( this.training.length === 0 ){
					this.vTourTargetArr.push('trainings');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusTraining = 'reload';
			});
		},
		getCertificate () {
			this.$http.get('summary/certificate')
			.then((resp) => {
				this.certificates = resp.data;
				this.statusCertificate = 'loaded';
				if( this.certificates.length === 0 ){
					this.vTourTargetArr.push('certificate');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusCertificate = 'reload';
			});
		},
		getVisa () {
			this.$http.get('summary/visa')
			.then((resp) => {
				this.visa = resp.data;
				this.statusVisa = 'loaded';
				if( this.visa.length === 0 ){
					this.vTourTargetArr.push('visa');
					this.startGuide();
				}
			})
			.catch(() => {
				this.statusVisa = 'reload';
			});
		},
		uploadProfileOn(){
			this.$store.dispatch(UPLOAD_PROFILE_OPEN);
		},
		isValidDate(dateString) {
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'errorOn', 'errorType', 'errorMessage', 'imageProfile', 'fname', 'rank'])
	},
	created(){
		this.getProfile();
		this.getContact();
		this.getEducation();
		this.getFamily();
		this.getEmployment();
		this.getLocaldocs();
		this.getForeigndocs();
		this.getTraining();
		this.getCertificate();
		this.getVisa();
	},
}

</script>

<style scoped>
	
	>>> .v-input__icon--append-outer .mdi-check-circle-outline{
		color: #4CAF50;
	}
	
	>>> .v-input--is-disabled input {
		color: #383333 !important;
	}
	
	>>> .v-label--is-disabled {
		color: #6b6767 !important;
	}
	
	>>> .theme--light.v-text-field.v-input--is-disabled .v-input__slot::before{
		border-image: repeating-linear-gradient(#dbd9d9, #dbd9d9, #dbd9d9) 1 repeat;
	}
</style>